import React from 'react';
import Navbar from '../components/NavBar';
import PhotoGrid from '../components/photogrid';
import purplelady from '../images/JT_purplelady.jpeg';
import heartgirl from '../images/JT_heartgirl.jpeg';
import washitapedress from '../images/JT_washitapeart.JPG';
import castle from '../images/JT_CastleEnvironment.jpg';
import tourbillon from '../images/JT_tourbillon.jpg';
import car1 from '../images/JT_car1.jpg';
import r35 from '../images/JT_gtrr35.jpg';
import racingsuit from '../images/JT_racingsuitposter.jpg';
import bubbles from '../images/JT_spacebubbles.jpg';
import laferrari from '../images/JT_laferrari.png';

const photos = [
    { src: laferrari, description: 'This piece depicts a LaFerrari using a combination of clay and acrylic paint. The front section is sculpted with modeling clay to create a dimensional effect, while acrylic paint is used to add detail, depth, and shading. A gradient background provides contrast, emphasizing the car’s form and motion.<br /> Dimensions: 16in x 20in' },
    { src: tourbillon, description: 'The driver\'s seat POV of the Buggatti Tourbillon showcasing modern analog design. Made with mixed media: watercolor, acrylic paint, color pencils, micon pens, and a paint marker. <br /> Dimensions: 18in x 16.5in' },
    { src: r35, description: 'Two different perspectives of the R35 GTR with a libertywalk body kit. One painted with graphite pencils and the other with color pencils. <br /> Dimensions: 18in x 24in' },
    { src: car1, description: 'Two Huracans drifting through the forest' },
    { src: bubbles, description: 'Used oil pastels' },
    { src: purplelady, description: 'Made with Clip Studio Paint digitally. A woman in a purple dress.' },
    { src: heartgirl, description: 'An expressive artwork filled with emotion and warmth.' },
    { src: racingsuit, description: 'Poster for my racing jersey design with arduino sensors' },
    { src: washitapedress, description: 'A unique dress crafted with washi tape.' },
    { src: castle, description: 'A serene castle in a beautiful environment.' },
];

function Art() {
    return (
        <div>
            <Navbar />
            <PhotoGrid photos={photos} />
        </div>
    );
}

export default Art;
