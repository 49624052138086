import React from 'react'
import { Link } from 'react-router-dom';
import AboutMe from '../components/AboutMe';
import ContactBunny from '../components/Contact';
import ProjectsKirby from '../components/Projects';
import MusicPlayer from "../components/MusicPlayer";



function Home () {
    return (
    <div id="header"> 
      <MusicPlayer />

      <div className="main-container">
        <Link to="/aboutme">
        <div className="HeartContainer">
            <div className="Heart01"> 
            <h1 className="ScholarlyAmbitionShadow">about me</h1>
            </div>  
            <div className="Heart02">        
            <AboutMe />
            </div>  
        </div>
        </Link>
        <Link to="/contact">
        <div className="BunnyContainer">
            <div className="Bunny01"> 
            <h1 className="ScholarlyAmbitionShadow">contact</h1>
            </div>  
            <div className="Bunny02">        
            <ContactBunny />
            </div>  
        </div>
        </Link>
        <Link to="/mywork">
        <div className="KirbyContainer">
            <div className="Kirby01"> 
            <h1 className="ScholarlyAmbitionShadow">my work</h1>
            </div>  
            <div className="Kirby02">        
            <ProjectsKirby />
            </div>  
        </div>
        </Link>

    </div>



</div>)
}
export default Home 