import React from 'react';
import Kirby03 from '../components/KirbyHeadphones';
import Box from '../components/box';
import Navbar from '../components/NavBar';


function MyWork() {
    return (
        <div className="container1"> {/* Parent container */}
            <Navbar />
            <div className='gridoffour'>
            <Box name="art" link="art"/>
            <Box name="games" link="games"/>
            <Box name="projects" link="projects"/>
            <Box name="clothing" link="clothing"/>
            </div>

            <div className='Kirby03Container'> {/* Container for Kirby03 */}
                <Kirby03 />
            </div>
        </div>
    );
}

export default MyWork;
