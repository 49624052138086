import React, { useRef, useState, useEffect } from "react";
import volumeIcon from "../images/volume-icon.png"; // Import the volume image
import song1 from "../soundtrack/01 The Day Everything Started.mp3";
import song2 from "../soundtrack/02 Room 707.mp3";
import song3 from "../soundtrack/03 Hachi in Love.mp3";
import song4 from "../soundtrack/04 IWTSHA.mp3";
import song5 from "../soundtrack/05 A Peaceful Morning.mp3";
import song6 from "../soundtrack/06 What a Wonderful Day.mp3";
import song7 from "../soundtrack/07 Sun Bath.mp3";
import cover1 from "../soundtrack/nana12.jpg";
import cover2 from "../soundtrack/nana3.jpg";
import cover3 from "../soundtrack/nana14.jpg";
import cover4 from "../soundtrack/nana13.jpg";
import cover5 from "../soundtrack/nana6.jpg";
import cover6 from "../soundtrack/nana10.jpg";
import cover7 from "../soundtrack/nana9.jpg";


const playlist = [
  { title: "The Day Everything Started", src: song1, cover: cover1 },
  { title: "Room 707", src: song2, cover: cover2 },
  { title: "Hachi in Love", src: song3, cover: cover3 },
  { title: "I Want to See Him Again!", src: song4, cover: cover4 },
  { title: "A Peaceful Morning", src: song5, cover: cover5 },
  { title: "What a Wonderful Day!", src: song6, cover: cover6 },
  { title: "Sun Bath", src: song7, cover: cover7 },

];




function MusicPlayer() {
    const [currentSongIndex, setCurrentSongIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [isLooping, setIsLooping] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(new Audio(playlist[currentSongIndex].src));
  
    useEffect(() => {
      const audio = audioRef.current;
      audio.volume = volume;
      audio.loop = isLooping;
  
      const updateTime = () => setCurrentTime(audio.currentTime);
      const loadMetadata = () => setDuration(audio.duration);
      const handleSongEnd = () => {
        if (!isLooping) {
          nextSong();
        }
      };
  
      audio.addEventListener("timeupdate", updateTime);
      audio.addEventListener("loadedmetadata", loadMetadata);
      audio.addEventListener("ended", handleSongEnd); // Listen for song end
  
      return () => {
        audio.removeEventListener("timeupdate", updateTime);
        audio.removeEventListener("loadedmetadata", loadMetadata);
        audio.removeEventListener("ended", handleSongEnd);
      };
    }, [volume, isLooping, currentSongIndex]); // Update when volume, loop, or song changes
  
    // Play or Pause Song
    const togglePlayPause = () => {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };
  
    // Seek through the song
    const handleSeekChange = (e) => {
      const newTime = parseFloat(e.target.value);
      setCurrentTime(newTime);
      audioRef.current.currentTime = newTime;
    };
  
    // Skip to Next Song
    const nextSong = () => {
      let nextIndex = (currentSongIndex + 1) % playlist.length;
      setCurrentSongIndex(nextIndex);
      audioRef.current.src = playlist[nextIndex].src;
      if (isPlaying) audioRef.current.play(); // Continue playing if already playing
    };
  
    // Go to Previous Song
    const prevSong = () => {
      let prevIndex = (currentSongIndex - 1 + playlist.length) % playlist.length;
      setCurrentSongIndex(prevIndex);
      audioRef.current.src = playlist[prevIndex].src;
      if (isPlaying) audioRef.current.play(); // Continue playing if already playing
    };
  
    // Toggle Loop Mode
    const toggleLoop = () => {
      setIsLooping(!isLooping);
      audioRef.current.loop = !isLooping;
    };
  
    // Change Volume
    const handleVolumeChange = (e) => {
      const newVolume = parseFloat(e.target.value);
      setVolume(newVolume);
      audioRef.current.volume = newVolume;
    };
  
    // Format Time (mm:ss)
    const formatTime = (time) => {
      if (!time) return "0:00";
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    };
  
    return (
      <div className="music-player">
        <img
          src={playlist[currentSongIndex].cover}
          alt={playlist[currentSongIndex].title}
          className="album-cover"
        />
        <h3>{playlist[currentSongIndex].title}</h3>
  
        {/* Seek Bar */}
        <input
          type="range"
          className="seek-bar"
          min="0"
          max={duration}
          step="0.1"
          value={currentTime}
          onChange={handleSeekChange}
        />
        <div className="time-display">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
  
        {/* Controls */}
        <div className="controls">
          <button onClick={prevSong}>⏮</button>
          <button onClick={togglePlayPause}>{isPlaying ? "⏸" : "▶"}</button>
          <button onClick={nextSong}>⏭</button>
        </div>

    {/* Volume Control */}
    <div className="volume-container">
    <img src={volumeIcon} alt="Volume" className="volume-icon" />
    <input
        type="range"
        className="volume-slider"
        min="0"
        max="1"
        step="0.1"
        value={volume}
        onChange={handleVolumeChange}
    />
    </div>

      </div>
    );
  }
  
  export default MusicPlayer;